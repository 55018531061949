import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(40),
    flexBasis: '10%',
    flexShrink: 0,
    color: theme.palette.secondary.main,
    paddingRight: 10,
  },
  secondaryHeading: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  panelDetails: {
    display: 'block',
  },
  text: {
    marginLeft: theme.spacing(5),
  },
});

type Props = {
  classes: Object,
  title: String,
  index: Number,
  content: String,
};

function AccordionItem({ classes, index, title, content }: Props) {
  const { t } = useTranslation();

  return (
    <div className={classes.root} itemScope>
      <Accordion style={{ borderRadius: '0px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{index.toString().padStart(2, '02')}</Typography>
          <Typography
            variant="h5"
            component="h4"
            className={classes.secondaryHeading}
            itemProp="name"
            dangerouslySetInnerHTML={{ __html: t(title) }}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          {content && (
            <Typography
              variant="body2"
              className={classes.text}
              color="textSecondary"
              itemProp="description"
              dangerouslySetInnerHTML={{ __html: t(content) }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default withStyles(styles)(AccordionItem);
