import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';

const styles = () => ({
  card: {
    backgroundColor: 'transparent',
    boxShadow: '0 0 0 0',
  },
  cardLogo: {
    height: '240px',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
  },
});

type Props = {
  classes: Object,
  img: Object,
  title: String,
  content: String,
  style: Object,
};

function PartnerCard({ img, title, content, classes, style }: Props) {
  const { t } = useTranslation();
  return (
    <Card itemScope className={classes.card}>
      <CardContent align="center">
        <meta itemProp="image" content={img} />
        <CardMedia style={style} className={classes.cardLogo} image={img} title={title} />

        <Typography variant="h5" component="h4" gutterBottom itemProp="name">
          {t(title)}
        </Typography>
        <Typography variant="subtitle1" component="div" itemProp="description">
          {t(content)}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(PartnerCard);
