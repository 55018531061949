import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ImageCard from './ImageCard';

const styles = theme => ({
  imageContainer: {
    paddingBottom: theme.spacing(5),
  },
});
type Props = {
  benefits: Array,
  classes: Object,
};
function ImageCardsContainer(props: Props) {
  const { benefits, classes } = props;

  return (
    <>
      {benefits.map((benefit, index) => (
        <Grid item className={classes.imageContainer} md={3} sm={6} xs={12} key={benefit.title}>
          <ImageCard
            index={index}
            image={benefit.image}
            size={benefit.size}
            title={benefit.title}
            src={benefit.src}
            hovered={benefit.hovered}
            action={benefit.action}
            content={benefit.content}
          >
            {benefit.content}
          </ImageCard>
        </Grid>
      ))}
    </>
  );
}
export default withStyles(styles)(ImageCardsContainer);
