import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  media: {
    height: 0,
    paddingLeft: '0px',
    paddingTop: '56.25%', // 16:9
  },
  content: {
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '6px',
  },
  avatar: {
    backgroundColor: theme.scalaa.primary.main,
  },
});

type Props = {
  classes: Object,
  title: String,
  media: String,
  contentTitle: String,
  content: String,
};

function ActivityCard({ title, media, contentTitle, content, classes }: Props) {
  const { t } = useTranslation();
  return (
    <Card itemScope className={classes.card} elevation={0}>
      <CardMedia className={classes.media} image={media} />
      <CardContent className={classes.content}>
        <Typography variant="h5" component="h4">
          {t(contentTitle)}
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          dangerouslySetInnerHTML={{ __html: t(title) }}
        />
        <Typography>{t(content)}</Typography>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(ActivityCard);
