import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccordionItem from './AccordionItem';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    padding: '2rem 1rem 3rem',
    [theme.breakpoints.only('xs')]: {
      padding: '0rem',
    },
  },
  service: {
    width: '100%',
    objectFit: 'cover',
    backgroundPosition: 'center top',
    borderRadius: 5,
    boxShadow: theme.shadows['1'],
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  subTitle: {
    width: '70%',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    margin: 'auto',
  },
}));

type Props = {
  benefits: Array,
  title: String,
  subTitle: String,
  backgroundImage: String,
  imgHeight: String,
};
function AccordionContainer(props: Props) {
  const { benefits, title, subTitle, backgroundImage, imgHeight } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container className={classes.layout}>
        <Grid item className={classes.headingContainer}>
          <Typography variant="h3" align="center" gutterBottom>
            {t(title)}
          </Typography>
          <Typography
            variant="subtitle1"
            component="h3"
            align="center"
            className={classes.subTitle}
            gutterBottom
          >
            {t(subTitle)}
          </Typography>
        </Grid>
        <Grid style={{ padding: '2rem 0 3rem' }} container>
          <Grid item sm={8}>
            {benefits.map((benefit, index) => (
              <AccordionItem
                key={benefit.title}
                index={index + 1}
                withIndex={benefit.withIndex}
                title={benefit.title}
                content={benefit.content}
              >
                {benefit.children}
              </AccordionItem>
            ))}
          </Grid>
          <Grid item sm={4} xs={12} style={{ paddingLeft: '2rem' }}>
            <img
              src={backgroundImage}
              alt={t(title)}
              className={classes.service}
              style={{
                height: imgHeight || 'auto',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default AccordionContainer;
