import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const styles = () => ({
  card: {
    margin: '2em',
  },
  cardLogo: {
    width: '120px',
    height: '120px',
    marginBottom: '10px',
  },
});

type Props = {
  classes: Object,
  img: Object,
  title: String,
  content: String,
};

function PortraitCard({ img, title, content, classes }: Props) {
  const { t } = useTranslation();
  return (
    <Card itemScope className={classes.card}>
      <CardContent align="center">
        <CardMedia className={classes.cardLogo} image={img} title={title} />
        <Typography variant="h5" gutterBottomdangerouslySetInnerHTML={{ __html: t(title) }} />
        <Typography
          align="justify"
          color="textSecondary"
          dangerouslySetInnerHTML={{ __html: t(content) }}
        />
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(PortraitCard);
