import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import ReactPlayer from 'react-player';
import Divider from '@material-ui/core/Divider';
import SectionTestimonial from '../../Components/PagesLeagues/SectionTestimonial';

import FooterScalaa from './Footer';
import Footer from '../../Components/Layout/Footer';
import { Images } from '../../Themes';
import {
  BulletPointContainer,
  PartnerCard,
  HeaderCard,
  ActivityCard,
} from '../../Components/Block';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import { PartyPopperIcon } from '../../Themes/Icon';

type Props = {
  classes: Object,
  history: Object,
};

const styles = theme => ({
  container: {
    backgroundColor: 'white',
  },
  coronalayout: {
    ...LayoutStyles.fullWidthLayout,

    margin: '1rem 4rem 3rem',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,

    margin: '2rem 3rem 3rem',
  },
  mainLayout: {
    ...LayoutStyles.fullWidthLayout,

    padding: '3rem 4rem 8rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    backgroundImage: `linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 70%)), url(${Images.scalaa.jumbo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: '3rem 0',
  },
  mainHeroTitle: {
    margin: '3rem 2rem 2rem 2rem',
    color: 'white',
    textShadow: '0.5px 0.5px #3c3c3c73',
  },
  mainHeroText: {
    margin: '2rem 2rem',
    color: 'white',
    textShadow: '0.5px 0.5px #3c3c3c73',
    fontWeight: 400,
    '&>*': {
      margin: 0,
    },
  },
  heroTitle: {
    color: 'white',
    textShadow: '0.5px 0.5px #3c3c3c73',
  },
  heroTitleLocation: {
    marginLeft: '0%',
  },
  heroForm: {
    backgroundColor: 'white',
    marginTop: '1.5em',
  },
  subHeroLayout: {
    width: '100%',
    height: '250px',
    margin: '3rem 0 3rem',
    backgroundImage: `url(${Images.scalaa.sub_jumbo})`,
    backgroundSize: 'cover',
  },
  benefit: {
    height: '100%',
    width: '100%',
    backgroundImage: `url(${Images.scalaa.benefit})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderRadius: 5,
    boxShadow: theme.shadows['1'],
  },
  service: {
    height: '400px',
    width: '90%',
    backgroundImage: `url(${Images.scalaa.service})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderRadius: 5,
    boxShadow: theme.shadows['1'],
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  activityLayout: {
    width: '100%',
    margin: '3rem 5rem 4rem',
  },
  ctaProLayout: {
    paddingBottom: '60px',
    paddingTop: '60px',
    backgroundColor: theme.scalaa.primary.thin,
  },
  ctaProSecondaryTitle: {
    color: theme.scalaa.primary.main,
  },
  ctaProContent: {
    ...LayoutStyles.fullWidthLayout,
  },
  partnerLayout: {
    padding: theme.spacing(10),
  },
  pushDownSections: {
    marginTop: -theme.spacing(7),
  },
  formControl: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  submitButton: {
    height: '55px',
  },
});

function Homepage({ classes, history }: Props) {
  const { t } = useTranslation();

  // const [service, setService] = React.useState('');
  // const [serviceError, setServiceError] = React.useState(null);

  // const handleSubmit = event => {
  //   setService(event.target.value);

  //   if (service === 'lille') {
  //     history.push(`/la-scalaa/scalaa`);
  //   }
  //   if (service === 'arras') {
  //     history.push(`/maison-des-aidants-de-l-arrageois/la-scalaa-arras`);
  //   }
  //   if (service === 'bethune') {
  //     history.push(`/le-relais-des-aidants/bethune`);
  //   }
  //   if (service === 'douai') {
  //     history.push(
  //       `/pfr-du-douaisis/plateforme-d-accompagnement-et-de-repit-des-aidants-du-douaisis`,
  //     );
  //   }
  //   if (service === 'cambrai') {
  //     history.push(`/maison-des-aidants-du-cambresis/maison-des-aidants-du-cambresis`);
  //   }
  //   if (service === 'carvin') {
  //     history.push(`/maison-des-aidants-d-henin-carvin/la-scalaa-carvin`);
  //   }
  //   if (service === 'lequesnoy') {
  //     history.push(`/pfr-du-valenciennois-quercitain/le-quesnoy`);
  //   }
  //   if (service === 'lens') {
  //     history.push(`/pfr-le-temps-d-une-pause/la-scalaa-lens`);
  //   }
  //   if (service === 'amiens') {
  //     history.push(`/pfr-aliis/la-scalaa-amiens`);
  //   }
  // };

  return (
    <>
      {/* <Helmet> use default meta from i18n:
        - ${Routes.currentNamespace()}.SEO.HOMEPAGE.TITLE
        - ${Routes.currentNamespace()}.SEO.HOMEPAGE.DESCRIPTION
      */}
      <Grid container className={classes.container}>
        {/* HERO CTA SECTION */}
        <Grid item className={classes.heroLayout}>
          <Grid item align="center" className={classes.mainLayout}>
            <Typography variant="h1" align="left" className={classes.mainHeroTitle} gutterBottom>
              {/* {t('SCALAA.HOMEPAGE.TITLE')} */}
              <PartyPopperIcon style={{ fontSize: 70 }} /> Nos services évoluent !
            </Typography>
            <Typography
              variant="h3"
              component="h2"
              align="left"
              className={classes.mainHeroText}
              gutterBottom
            >
              lascalaa.fr devient soutenirlesaidants.fr
            </Typography>
            <Typography
              variant="h4"
              align="left"
              className={classes.mainHeroText}
              dangerouslySetInnerHTML={{
                __html:
                  '<p>Retrouvez l’ensemble des services proposés et bien plus encore sur soutenirlesaidants.fr</p><p>Développé par la Fédération des Plateformes de Répit, nous sommes à présent disponibles sur toute la France !</p>',
              }}
            />
            <Grid align="right">
              <Button
                size="large"
                variant="contained"
                color="primary"
                href="https://www.soutenirlesaidants.fr/"
              >
                Découvrir
              </Button>
            </Grid>
            {/* <Grid container justify="center" spacing={2}>
              <Grid item md={6}>
                <Select
                  align="left"
                  fullWidth
                  displayEmpty
                  value={service}
                  className={classes.formControl}
                  onChange={e => {
                    setService(e.target.value);
                    setServiceError(null);
                  }}
                  input={<OutlinedInput labelWidth={0} name="activity" id="activity-simple" />}
                  variant="outlined"
                  error={serviceError !== null}
                >
                  <MenuItem value="" disabled>
                    <em>Sélectionner la structure la plus proche de chez vous</em>
                  </MenuItem>
                  <MenuItem value="arras">Arras</MenuItem>
                  <MenuItem value="bethune">Bethune</MenuItem>
                  <MenuItem value="cambrai">Cambrai</MenuItem>
                  <MenuItem value="carvin">Carvin</MenuItem>
                  <MenuItem value="douai">Douai</MenuItem>
                  <MenuItem value="lens">Lens Lievin</MenuItem>
                  <MenuItem value="lequesnoy">Le Quesnoy</MenuItem>
                  <MenuItem value="lille">Métropole Lille</MenuItem>
                  <MenuItem value="amiens">Somme Est</MenuItem>
                </Select>
              </Grid>
              <Grid item md={2}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleSubmit}
                >
                  Selectionner
                </Button>
              </Grid>
            </Grid> */}

            {/* <Typography
              variant="subtitle2"
              align="center"
              className={classNames(classes.heroTitleLocation, classes.heroTitle)}
              paragraph
            >
              {t('SCALAA.HOMEPAGE.SUBTITLE.LOCATION')}
            </Typography> */}
          </Grid>
        </Grid>

        {/* BENEFIT SECTION */}
        <Grid
          spacing={4}
          container
          className={classNames(classes.layout, classes.pushDownSections)}
        >
          <Grid item md={4} sm={4} xs={12}>
            <HeaderCard
              img={Images.scalaa.benefit_card_1}
              title="SCALAA.HOMEPAGE.BENEFIT.VALUE_1.TITLE"
              content="SCALAA.HOMEPAGE.BENEFIT.VALUE_1.CONTENT"
            />
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <HeaderCard
              img={Images.scalaa.benefit_card_2}
              title="SCALAA.HOMEPAGE.BENEFIT.VALUE_2.TITLE"
              content="SCALAA.HOMEPAGE.BENEFIT.VALUE_2.CONTENT"
            />
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <HeaderCard
              img={Images.scalaa.benefit_card_3}
              title="SCALAA.HOMEPAGE.BENEFIT.VALUE_3.TITLE"
              content="SCALAA.HOMEPAGE.BENEFIT.VALUE_3.CONTENT"
            />
          </Grid>
        </Grid>

        {/* TUTO SECTION */}
        <Grid container justify="center" className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" gutterBottom>
                {t('SCALAA.HOMEPAGE.TUTO.TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('SCALAA.HOMEPAGE.TUTO.SUBTITLE')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ReactPlayer
              width="100%"
              loop="true"
              playing="true"
              controls="true"
              url="/images/leagues/scalaa/Scalaa-compressed.mp4"
            />
          </Grid>
        </Grid>

        {/* WHO AR WE SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" gutterBottom>
                {t('SCALAA.HOMEPAGE.WHY.TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('SCALAA.HOMEPAGE.WHY.SUBTITLE')}
              </Typography>
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item md={4} xs={12}>
              <div className={classes.benefit} />
            </Grid>
            <Grid item md={8} xs={12}>
              <BulletPointContainer
                benefits={[
                  {
                    bullet: ' ',
                    title: 'SCALAA.HOMEPAGE.WHY.VALUE_1.TITLE',
                    content: 'SCALAA.HOMEPAGE.WHY.VALUE_1.CONTENT',
                  },
                  {
                    bullet: ' ',
                    title: 'SCALAA.HOMEPAGE.WHY.VALUE_2.TITLE',
                    content: 'SCALAA.HOMEPAGE.WHY.VALUE_2.CONTENT',
                  },
                  {
                    bullet: ' ',
                    title: 'SCALAA.HOMEPAGE.WHY.VALUE_3.TITLE',
                    content: 'SCALAA.HOMEPAGE.WHY.VALUE_3.CONTENT',
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* PRO DISCOVER PORTRAIT SECTION */}
        <SectionTestimonial
          title={t('SCALAA.HOMEPAGE.DISCOVER.TITLE')}
          subTitle={t('SCALAA.HOMEPAGE.DISCOVER.SUBTITLE')}
          testimonials={[
            {
              avatar: Images.scalaa.avatar_customer_1,
              title: t('SCALAA.HOMEPAGE.DISCOVER.VALUE_1.TITLE'),
              location: t('SCALAA.HOMEPAGE.DISCOVER.VALUE_1.LOCATION'),
              content: 'SCALAA.HOMEPAGE.DISCOVER.VALUE_1.CONTENT',
            },
            {
              avatar: Images.scalaa.avatar_customer_2,
              title: t('SCALAA.HOMEPAGE.DISCOVER.VALUE_2.TITLE'),
              location: t('SCALAA.HOMEPAGE.DISCOVER.VALUE_2.LOCATION'),
              content: 'SCALAA.HOMEPAGE.DISCOVER.VALUE_2.CONTENT',
            },
            {
              avatar: Images.scalaa.avatar_customer_3,
              title: t('SCALAA.HOMEPAGE.DISCOVER.VALUE_3.TITLE'),
              location: t('SCALAA.HOMEPAGE.DISCOVER.VALUE_3.LOCATION'),
              content: 'SCALAA.HOMEPAGE.DISCOVER.VALUE_3.CONTENT',
            },
          ]}
          buttonLabel="Découvrir toutes les activités"
          onButtonClick={() => history.push('/recherche/activites')}
        />

        {/* SUBHERO CTA SECTION */}
        <Grid item className={classes.subHeroLayout}>
          <Grid item align="center" className={classes.layout}>
            <Typography variant="h2" align="center" className={classes.heroTitle} gutterBottom>
              {t('SCALAA.HOMEPAGE.CTA.TITLE')}
            </Typography>
            <Grid item sm={6} />
            <Typography
              variant="subtitle1"
              align="center"
              component="h3"
              className={classNames(classes.heroTitleLocation, classes.heroTitle)}
              paragraph
            >
              {t('SCALAA.HOMEPAGE.SUBTITLE.LOCATION')}
            </Typography>
          </Grid>
        </Grid>

        {/* SERVICE SECTION */}
        <Grid container className={classes.layout}>
          <Grid spacing={2} container>
            <Grid item className={classes.headingContainer}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center" gutterBottom>
                  {t('SCALAA.HOMEPAGE.SERVICE.TITLE')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                  {t('SCALAA.HOMEPAGE.SERVICE.SUBTITLE')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={8} xs={12}>
              <BulletPointContainer
                benefits={[
                  {
                    bullet: ' ',
                    title: 'SCALAA.HOMEPAGE.SERVICE.VALUE_1.TITLE',
                    content: 'SCALAA.HOMEPAGE.SERVICE.VALUE_1.CONTENT',
                  },
                  {
                    bullet: ' ',
                    title: 'SCALAA.HOMEPAGE.SERVICE.VALUE_2.TITLE',
                    content: 'SCALAA.HOMEPAGE.SERVICE.VALUE_2.CONTENT',
                  },
                ]}
              />
              <Grid item align="center" style={{ padding: '3em' }}>
                <Button
                  className={classes.heroForm}
                  variant="contained"
                  size="large"
                  component={Link}
                  to="/la-scalaa/scalaa/"
                >
                  {t('SCALAA.HOMEPAGE.SERVICE.NAME')}
                </Button>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className={classes.service} />
            </Grid>
          </Grid>
        </Grid>

        {/* ACTIVITY SECTION */}
        <Grid container className={classes.activityLayout}>
          <Grid spacing={3} container justify="flex-start">
            <Grid item md={3} sm={4} xs={12}>
              <ActivityCard
                media={Images.scalaa.activity_1}
                title="SCALAA.HOMEPAGE.ACTIVITY.VALUE_1.TITLE"
                location={t('SCALAA.HOMEPAGE.ACTIVITY.VALUE_1.LOCATION')}
                contentTitle="SCALAA.HOMEPAGE.ACTIVITY.VALUE_1.CONTENT_TITLE"
                content="SCALAA.HOMEPAGE.ACTIVITY.VALUE_1.CONTENT"
              />
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <ActivityCard
                media={Images.scalaa.activity_2}
                title="SCALAA.HOMEPAGE.ACTIVITY.VALUE_2.TITLE"
                location={t('SCALAA.HOMEPAGE.ACTIVITY.VALUE_2.LOCATION')}
                contentTitle="SCALAA.HOMEPAGE.ACTIVITY.VALUE_2.CONTENT_TITLE"
                content="SCALAA.HOMEPAGE.ACTIVITY.VALUE_2.CONTENT"
              />
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <ActivityCard
                media={Images.scalaa.activity_3}
                title="SCALAA.HOMEPAGE.ACTIVITY.VALUE_3.TITLE"
                location={t('SCALAA.HOMEPAGE.ACTIVITY.VALUE_3.LOCATION')}
                contentTitle="SCALAA.HOMEPAGE.ACTIVITY.VALUE_3.CONTENT_TITLE"
                content="SCALAA.HOMEPAGE.ACTIVITY.VALUE_3.CONTENT"
              />
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <ActivityCard
                media={Images.scalaa.activity_4}
                title="SCALAA.HOMEPAGE.ACTIVITY.VALUE_4.TITLE"
                location={t('SCALAA.HOMEPAGE.ACTIVITY.VALUE_4.LOCATION')}
                contentTitle="SCALAA.HOMEPAGE.ACTIVITY.VALUE_4.CONTENT_TITLE"
                content="SCALAA.HOMEPAGE.ACTIVITY.VALUE_4.CONTENT"
              />
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <ActivityCard
                media={Images.scalaa.activity_5}
                title="SCALAA.HOMEPAGE.ACTIVITY.VALUE_5.TITLE"
                location={t('SCALAA.HOMEPAGE.ACTIVITY.VALUE_5.LOCATION')}
                contentTitle="SCALAA.HOMEPAGE.ACTIVITY.VALUE_5.CONTENT_TITLE"
                content="SCALAA.HOMEPAGE.ACTIVITY.VALUE_5.CONTENT"
              />
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <ActivityCard
                media={Images.scalaa.activity_6}
                title="SCALAA.HOMEPAGE.ACTIVITY.VALUE_6.TITLE"
                location={t('SCALAA.HOMEPAGE.ACTIVITY.VALUE_6.LOCATION')}
                contentTitle="SCALAA.HOMEPAGE.ACTIVITY.VALUE_6.CONTENT_TITLE"
                content="SCALAA.HOMEPAGE.ACTIVITY.VALUE_6.CONTENT"
              />
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <ActivityCard
                media={Images.scalaa.activity_1}
                title="SCALAA.HOMEPAGE.ACTIVITY.VALUE_7.TITLE"
                location={t('SCALAA.HOMEPAGE.ACTIVITY.VALUE_7.LOCATION')}
                contentTitle="SCALAA.HOMEPAGE.ACTIVITY.VALUE_7.CONTENT_TITLE"
                content="SCALAA.HOMEPAGE.ACTIVITY.VALUE_7.CONTENT"
              />
            </Grid>
            <Grid item md={3} sm={4} xs={12}>
              <ActivityCard
                media={Images.scalaa.activity_2}
                title="SCALAA.HOMEPAGE.ACTIVITY.VALUE_8.TITLE"
                location={t('SCALAA.HOMEPAGE.ACTIVITY.VALUE_8.LOCATION')}
                contentTitle="SCALAA.HOMEPAGE.ACTIVITY.VALUE_8.CONTENT_TITLE"
                content="SCALAA.HOMEPAGE.ACTIVITY.VALUE_8.CONTENT"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* PRO CTA SECTION */}
        <Grid container className={classes.ctaProLayout}>
          <Grid item align="center" className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" gutterBottom>
                {t('SCALAA.PRO.TITLE')}
              </Typography>

              <Typography
                variant="subtitle1"
                component="h3"
                className={classes.ctaProSecondaryTitle}
                align="center"
                gutterBottom
              >
                {t('SCALAA.PRO.SUBTITLE')}
              </Typography>

              <Typography
                variant="subtitle2"
                component="h4"
                className={classes.ctaProContent}
                align="center"
                paragraph
              >
                {t('SCALAA.PRO.SUBTITLE.BODY')}
              </Typography>
              <Button to="/pro" component={Link} variant="outlined" color="inherit">
                {t('SCALAA.PRO.ROOM')}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* PARTNER SECTION */}
        <Grid container className={classes.partnerLayout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" gutterBottom>
                {t('SCALAA.HOMEPAGE.PARTNER.TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('SCALAA.HOMEPAGE.PARTNER.SUBTITLE')}
              </Typography>
            </Grid>
            <Grid container align="center">
              <Grid item md={6} xs={12}>
                <PartnerCard
                  img={Images.scalaa.logo}
                  title="SCALAA.HOMEPAGE.PARTNER.PARTNER_SCALAA.TITLE"
                  content="SCALAA.HOMEPAGE.PARTNER.PARTNER_SCALAA.CONTENT"
                />
                <Button color="inherit" href="http://feron-vrau.com/">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <PartnerCard
                  img={Images.wello.partner}
                  title="WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE"
                  content="WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT"
                />
                <Button color="inherit" href="https://www.wello.fr">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* FOOTER SECTION */}
      <FooterScalaa />
      <Divider />
      <Footer />
    </>
  );
}

export default withStyles(styles)(Homepage);
