import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  card: {
    maxWidth: 400,
  },
  header: {
    marginTop: '-18px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
});

type Props = {
  classes: Object,
  avatar: String,
  title: String,
  location: String,
  content: String,
};

function TestimonialCard({ title, avatar, content, location, classes }: Props) {
  const { t } = useTranslation();
  return (
    <Card itemScope>
      <CardContent>
        <Typography
          variant="body1"
          gutterBottom
          itemProp="description"
          dangerouslySetInnerHTML={{
            __html: t(content),
          }}
        />
      </CardContent>
      <CardHeader
        className={classes.header}
        avatar={
          <Avatar aria-label="Recipe" src={avatar} className={classes.avatar} alt={title}>
            R
          </Avatar>
        }
        title={
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(title),
            }}
          />
        }
        subheader={location}
      />
    </Card>
  );
}

export default withStyles(styles)(TestimonialCard);
