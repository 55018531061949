import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import LayoutStyles, { isMobile } from '../Layout/Styles/LayoutStyles';
import TestimonialCard from '../Block/TestimonialCard';

const useStyles = makeStyles(() => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    padding: '2rem 3rem 3rem',
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  card: {
    padding: 10,
  },
}));

type Props = {
  title: String,
  subTitle: String,
  testimonials: Object,
};

function SectionTestimonial({ title, subTitle, testimonials }: Props) {
  const classes = useStyles();
  const settings = {
    arrows: !isMobile,
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '90px',
    slidesToShow: 3,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Grid className={classes.layout}>
      <Grid item className={classes.headingContainer}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
            {subTitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Slider {...settings}>
          {testimonials.map(testimonial => (
            <Grid item className={classes.card} key={testimonial.title}>
              <TestimonialCard
                avatar={testimonial.avatar}
                title={testimonial.title}
                location={testimonial.location}
                content={testimonial.content}
              />
            </Grid>
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
}
export default SectionTestimonial;
