import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  bulletPointItem: {
    position: 'relative',
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 0,
    },
  },
  backgroundIndexAbsolute: {
    opacity: 0.5,
    position: 'absolute',
    width: '100%',
    top: '-5px',
    left: -50,
    color: theme.palette.text.hint,
    zIndex: 0,
    paddingLeft: theme.spacing(10),
  },
  offsetBackgroundIndex: {
    paddingTop: theme.spacing(4),
    zIndex: 1,
  },
  paragraph: {
    '&>*': {
      margin: 0,
    },
  },
});

type Props = {
  classes: Object,
  index: Number,
  title: String,
  content: String,
};
function BulletPointItem({ index, title, content, classes }: Props) {
  const { t } = useTranslation();

  return (
    <Grid className={classes.bulletPointItem} itemScope>
      <Grid className={classes.offsetBackgroundIndex}>
        <Typography
          variant="h3"
          component="div"
          className={classes.backgroundIndexAbsolute}
          itemProp="position"
        >
          {index}
        </Typography>
        {title && (
          <Typography
            variant="h5"
            component="h3"
            gutterBottom
            itemProp="name"
            dangerouslySetInnerHTML={{ __html: t(title) }}
          />
        )}
        <Typography
          variant="subtitle1"
          component="div"
          itemProp="description"
          dangerouslySetInnerHTML={{ __html: t(content) }}
          className={classes.paragraph}
        />
      </Grid>
    </Grid>
  );
}
export default withStyles(styles)(BulletPointItem);
