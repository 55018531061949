import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import { grey } from '@material-ui/core/colors';

const styles = () => ({
  cardLogo: {
    height: '50px',
    width: '50px',
    marginRight: '13px',
  },
  cardContent: {
    height: '45px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    maxHeight: '5.8em',
    whiteSpace: 'normal',
  },
});
type Props = {
  classes: Object,
  img: String,
  title: String,
  content: String,
};

function HeaderCard({ title, img, content, classes }: Props) {
  const { t } = useTranslation();
  return (
    <Card itemScope>
      <CardContent>
        <Grid container>
          <meta itemProp="image" content={img} />
          <CardMedia className={classes.cardLogo} image={img} title={title} />
          <Grid item xs={9}>
            <Typography variant="h5" component="h3" gutterBottom itemProp="name">
              {t(title)}
            </Typography>
            <Typography
              color="textSecondary"
              className={classes.cardContent}
              itemProp="description"
            >
              {t(content)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardMedia
        style={{
          backgroundColor: grey[500],
        }}
      />
    </Card>
  );
}

export default withStyles(styles)(HeaderCard);
