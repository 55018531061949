import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { getExpertiseStyle } from '../../Themes/Expertises';

const styles = theme => ({
  card: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  content: {
    paddingTop: '0px',
  },
  avatar: {
    backgroundColor: theme.scalaa.primary.main,
    width: '40px',
    height: '40px',
    display: 'block',
  },
  chip: {
    marginLeft: '0px',
    marginRight: '3px',
    marginBottom: '3px',
    height: '20px',
    color: 'white',
    backgroundColor: theme.scalaa.primary.dark,
  },
});

type Props = {
  classes: Object,
  avatar: String,
  title: String,
  location: String,
  leadType: String,
  leadContent: String,
  content: String,
};

function LeadCard(props: Props) {
  const { avatar, title, location, leadType, leadContent, content, classes } = props;
  const { t } = useTranslation();
  return (
    <Card itemScope className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="Recipe" src={avatar} className={classes.avatar}>
            R
          </Avatar>
        }
        title={<Typography dangerouslySetInnerHTML={{ __html: t(title) }} />}
        subheader={location}
      />
      <CardContent className={classes.content}>
        <Chip
          className={classes.chip}
          style={{
            backgroundColor: getExpertiseStyle(leadType).color,
          }}
          label={leadContent}
        />
      </CardContent>
      <CardContent className={classes.content}>
        <Typography dangerouslySetInnerHTML={{ __html: t(content) }} />
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(LeadCard);
