import React from 'react';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({
  cardContainer: {
    height: '100%',
  },
  media: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  actionArea: {
    height: '100%',
    width: '100%',
  },
  text: {
    backgroundColor: 'rgba(0,0,0,0.55)',
    flexShrink: 0,
    textAlign: 'center',
    display: 'flex',
    color: 'white',
    alignItems: 'flex-end',
    minHeight: '360px',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 20,
  },
  action: {
    marginTop: -theme.spacing(6),
  },
  showContent: {
    visibility: 'visible',
    opacity: 1,
    transition: '1s',
  },
  hideContent: {
    visibility: 'hiddent',
    opacity: 0,
    transition: '1s',
  },
});

type Props = {
  classes: Object,
  title: String,
  content: String,
  src: String,
  size: Number,
  image: String,
  children: Function,
  hovered: Boolean,
  action: Object,
  t: Function,
};

class ImageCard extends React.Component<Props> {
  state = {
    hover: null,
  };

  onHover = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    const { classes, src, size, image, title, content, children, hovered, action, t } = this.props;
    const { hover } = this.state;

    return (
      <Card
        className={classes.cardContainer}
        style={size && { height: size }}
        onFocus={this.onHover}
        onMouseOver={this.onHover}
        onBlur={this.mouseOut}
        onMouseOut={this.mouseOut}
        itemScope
        itemType="http://schema.org/ListItem"
      >
        <meta itemProp="image" content={image} />
        <CardMedia className={classes.media} image={image} src={src} title={title}>
          <CardActionArea className={classes.actionArea}>
            {children && (
              <Typography
                className={`
                    ${classes.text} ${
                  !hovered || (hovered && hover) ? classes.showContent : classes.hideContent
                }
                  `}
                component="p"
                itemProp="description"
                dangerouslySetInnerHTML={{ __html: t(content) }}
              />
            )}
          </CardActionArea>
          {action && <Grid className={classes.action}>{action}</Grid>}
        </CardMedia>
      </Card>
    );
  }
}
export default withStyles(styles)(withTranslation()(ImageCard));
