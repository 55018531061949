import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

type Props = {
  classes: Object,
};

const styles = theme => ({
  nobullets: {
    listStyleType: 'none',
  },
  footerLayout: {
    backgroundColor: grey[200],
    padding: theme.spacing(4),
  },
  link: {
    textDecorationLine: 'none',
    color: grey[600],
  },
  fab: {
    margin: theme.spacing(1),
  },
});

function FooterScalaa({ classes }: Props) {
  const { t } = useTranslation();

  return (
    <footer className={classes.footerLayout}>
      <Grid item md={12}>
        <Typography variant="h4" component="h2" gutterBottom>
          {t('SCALAA.FOOTER.TITLE')}
        </Typography>
      </Grid>
      <Grid container justify="space-around" spacing={3}>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item md={12}>
            <Typography variant="h6" component="h3">
              {t('SCALAA.FOOTER.CONTENT_2.TITLE')}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <a href="/maison-des-aidants-de-l-arrageois/la-scalaa-arras" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Maison des Aidants de l’Arrageois - Arras
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/le-relais-des-aidants/bethune" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Le Relais des Aidants - Béthune
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a
              href="/maison-des-aidants-du-cambresis/maison-des-aidants-du-cambresis"
              className={classes.link}
            >
              <Typography variant="caption" color="textSecondary">
                Maison des Aidants du Cambrésis - Cambrai
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/maison-des-aidants-d-henin-carvin/la-scalaa-carvin" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Maison des Aidants d’Hénin-Carvin - Carvin
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a
              href="/pfr-du-douaisis/plateforme-d-accompagnement-et-de-repit-des-aidants-du-douaisis"
              className={classes.link}
            >
              <Typography variant="caption" color="textSecondary">
                PFR du Douaisis - Douai
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/pfr-le-temps-d-une-pause/la-scalaa-lens" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                PFR Le Temps d&apos;une pause - Lens
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/pfr-du-valenciennois-quercitain/le-quesnoy" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                PFR des Aidants Familiaux - Le Quesnoy
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/la-scalaa/scalaa" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                La scalaa - Lille
              </Typography>
            </a>
          </Grid>
          <Grid item md={12}>
            <a href="/pfr-aliis/la-scalaa-amiens" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                PFR ALIIS - Somme Est
              </Typography>
            </a>
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <Grid item md={12}>
            <Typography variant="h6" component="h3">
              {t('SCALAA.FOOTER.CONTENT_1.TITLE')}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <a href="/nos-offres/accompagnement-et-sortie" className={classes.link}>
              <Typography variant="caption" color="textSecondary">
                Accompagnement et Sortie
              </Typography>
            </a>
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={12} component="nav" className={classes.nobullets} />
      </Grid>
    </footer>
  );
}

export default withStyles(styles)(FooterScalaa);
