import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BulletPointItem from './BulletPointItem';

const styles = () => ({});
type Props = {
  benefits: Array,
};
function BulletPointContainer(props: Props) {
  const { benefits } = props;

  return (
    <>
      {benefits.map(benefit => (
        <BulletPointItem
          key={benefit.title}
          index={benefit.bullet}
          title={benefit.title}
          content={benefit.content}
        />
      ))}
    </>
  );
}
export default withStyles(styles)(BulletPointContainer);
